import {
  Store as VuexStore,
  CommitOptions,
  Module
} from 'vuex'

import { RootState } from '@/store'
import { state } from './state'
import { mutations, Mutations } from './mutations'
import type { MaterialState } from './state'

export { MaterialState }

export type MaterialStore<S = MaterialState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'>
  & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
      key: K,
      payload: P,
      options?: CommitOptions
    ): ReturnType<Mutations[K]>
  }
export const store: Module<MaterialState, RootState> = {
  state,
  mutations
}
