import { RequestResult } from '@/types/axios'
import service from '@/utils/request'
import { aliOssURL } from './config'
import { OssConfigType } from './types/aliOss'
import AliOss, { MultipartUploadResult } from 'ali-oss'
import { splitFileName } from '@/utils'

/**
 * 获取阿里OSS配置
 * @param fileType (创作模版 1,)
 * @returns
 */
export function getAliOssConfig(fileType: number, platform?: number) {
  const params = {
    fileType,
    platform: platform || 3
  }
  return service.request<any, RequestResult<{ credentials: OssConfigType }>>({
    url: aliOssURL + '/v2/common/oss/credentials',
    method: 'GET',
    params
  })
}

/**
 * 阿里oss分片上传
 * @param config
 * @param file
 * @param progress
 * @param checkpoint
 * @returns
 */
export function multipartUpload(config: OssConfigType, file: File, progress: (arg0: any, arg1: any, arg2: AliOss) => void, checkpoint?: any) {
  const client = new AliOss(config)
  return new Promise((resolve: (value: MultipartUploadResult) => void, reject) => {
    const fileName = splitFileName(file.name)
    const url = config.dir + fileName.prefix + '-' + Date.now() + '.' + fileName.suffix
    client.multipartUpload(url, file, {
      progress: (p, checkpoint) => {
        progress(p, checkpoint, client)
      },
      checkpoint
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
