import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/material'
  },
  {
    path: '/uploadWorks',
    name: '上传作品',
    component: () => import(/* webpackChunkName: "uploadWorks" */ '@/views/uploadWorks/index.vue'),
    children: []
  },
  {
    path: '/material',
    name: '素材分享',
    component: () => import(/* webpackChunkName: "/" */ '@/views/material/index.vue'),
    children: []
  },
  {
    path: '/news',
    name: '最新动态',
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/index.vue')
  },
  {
    path: '/userServe',
    name: '服务协议',
    component: () => import(/* webpackChunkName: "userServe" */ '@/views/userServe/index.vue')
  },
  {
    path: '/copyright',
    name: '版权纠纷处理',
    component: () => import(/* webpackChunkName: "copyright" */ '@/views/copyright/index.vue')
  },
  {
    path: '/tutelage',
    name: '家长监护',
    component: () => import(/* webpackChunkName: "tutelage" */ '@/views/tutelage/index.vue')
  },
  {
    path: '/self-audit',
    name: '自审制度',
    component: () => import(/* webpackChunkName: "self-audit" */ '@/views/self-audit/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
