import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, createSlots as _createSlots, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6f2a02b4")
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "upload-image__tip" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "el-upload-list__item-actions" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
_popScopeId()

import { useUpload } from '@/hooks/component/useUpload'
import { downloadFile } from '@/utils'
import { MultipartUploadResult } from 'ali-oss'
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type'
import { getCurrentInstance, ComponentInternalInstance, ref, watchEffect } from 'vue'


export default _defineComponent({
  props: {
    modelValue: { type: [String, null], required: true },
    action: { type: String, required: false, default: '*' },
    accept: { type: String, required: false, default: '*' },
    showFileList: { type: Boolean, required: false, default: false },
    btnText: { type: String, required: false, default: '上传' },
    fileSizeLimit: { type: Number, required: false },
    fileType: { type: Number, required: false, default: 1 },
    autoUpload: { type: Boolean, required: false, default: true },
    isSlot: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["on-change", "on-success", "on-error", "update:modelValue"] as unknown as undefined,
  setup(__props: {
    modelValue: string | null
    action?: string
    accept?: string
    showFileList?: boolean
    btnText?: string
    fileSizeLimit?: number
    fileType?: number
    autoUpload?: boolean
    isSlot?: boolean
  }, { emit }: { emit: ({
  (event: 'on-change', file: UploadFile): void
  (event: 'on-success', res: MultipartUploadResult): void
  (event: 'on-error'): void
  (event: 'update:modelValue', val: string): void
}), expose: any, slots: any, attrs: any }) {

const props = __props


const elUploadImage = ref()
const imageUrl = ref('')
const fileList = ref<{ name: string, url: string }[]>([])
const modelArr = ref<string[]>([])
const dialogVisible = ref<boolean>(false)
const dialogImageUrl = ref('')

watchEffect(() => {
  if (!props.showFileList) {
    imageUrl.value = props.modelValue ?? ''
  } else {
    if (props.modelValue) {
      const arr = props.modelValue?.split(',') as []
      if (arr.length > 0) {
        fileList.value = arr.filter(item => item !== '').map(item => {
          return { name: item as string, url: item as string }
        })
      }
    }
  }
})



const { proxy } = getCurrentInstance() as ComponentInternalInstance
const { aliUpload, uploadStatus } = useUpload()

/** 选择文件 */
const handleOnChange = (file: UploadFile) => {
  console.log(file)
  const fileSize = file.size / 1024 / 1024
  if (props.fileSizeLimit && fileSize > props.fileSizeLimit) {
    proxy?.$feedback.msgWarning(`文件大小不能超过${props.fileSizeLimit}MB哦~`)
    return
  }
  if (!props.autoUpload) {
    emit('on-change', file)
    return
  }
  if (!file || !file.raw) {
    proxy?.$feedback.notifyWarning({
      title: '上传删除',
      message: '上传错误，请重试！'
    })
    return
  }
  upload(file.raw)
}

/** 上传中 */
const upload = (file: File) => {
  if (uploadStatus.value === 'loading') {
    proxy?.$feedback.msgWarning('上传中！请勿重复上传！')
    return
  }
  aliUpload(props.fileType, file).then(res => {
    if (res) {
      /** 上传成功回调 */
      emit('on-success', res)
      const imageUrl = res.res.requestUrls[0].split('?')[0]
      handleSuccess(imageUrl, res.name)
    }
  }).catch(err => {
    emit('on-error')
    proxy?.$feedback.msgError('上传失败,请重新上传！！！')
    console.error(err)
  })
}

/** 上传成功后 */
const handleSuccess = (url: string, name: string) => {
  if (!props.showFileList) {
    imageUrl.value = url
    emit('update:modelValue', imageUrl.value)
  } else {
    fileList.value.push({ name: name, url: url })
    modelArr.value = fileList.value.map(item => item.url)
    emit('update:modelValue', modelArr.value.join(','))
  }
}

/** 预览图片 */
const handlePictureCardPreview = (file: UploadFile) => {
  dialogVisible.value = true
  dialogImageUrl.value = file.url as string
}

/** 下载图片 */
const handleDownload = (file: UploadFile) => {
  downloadFile(file.url as string, file.name)
}

const handleRemove = () => {
  imageUrl.value = ''
  emit('update:modelValue', '')
}

/** 删除图片列表 */
const handleFileListRemove = (file: UploadFile) => {
  const fIndex = fileList.value.map(f => f.url).indexOf(file.url as string)
  if (fIndex > -1) {
    fileList.value.splice(fIndex, 1)
    modelArr.value = fileList.value.map(item => item.url)
    emit('update:modelValue', modelArr.value.join(','))
  }
}


return (_ctx: any,_cache: any) => {
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_zoom_in = _resolveComponent("zoom-in")!
  const _component_Download = _resolveComponent("Download")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      ref: elUploadImage,
      class: "upload-image",
      action: __props.action,
      "show-file-list": __props.showFileList,
      accept: __props.accept,
      "auto-upload": false,
      "list-type": __props.showFileList ? 'picture-card' : 'picture',
      "on-change": handleOnChange,
      "file-list": fileList.value
    }, _createSlots({
      default: _withCtx(() => [
        (!__props.showFileList)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!imageUrl.value)
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    class: "upload-image-icon"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_plus)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: imageUrl.value,
                    class: "image"
                  }, null, 8, _hoisted_1))
            ], 64))
          : (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              class: "upload-image-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_plus)
              ]),
              _: 1
            }))
      ]),
      _: 2
    }, [
      (!__props.showFileList && imageUrl.value)
        ? {
            name: "tip",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_icon, {
                  class: "upload-image__tip--delete",
                  onClick: handleRemove
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Delete)
                  ]),
                  _: 1
                })
              ])
            ])
          }
        : undefined,
      (__props.showFileList)
        ? {
            name: "file",
            fn: _withCtx(({ file }) => [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  class: "image",
                  src: file.url
                }, null, 8, _hoisted_3),
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("span", {
                    class: "el-upload-list__item-preview",
                    onClick: ($event: any) => (handlePictureCardPreview(file))
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_zoom_in)
                      ]),
                      _: 1
                    })
                  ], 8, _hoisted_5),
                  _createElementVNode("span", {
                    class: "el-upload-list__item-delete",
                    onClick: ($event: any) => (handleDownload(file))
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Download)
                      ]),
                      _: 1
                    })
                  ], 8, _hoisted_6),
                  _createElementVNode("span", {
                    class: "el-upload-list__item-delete",
                    onClick: ($event: any) => (handleFileListRemove(file))
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Delete)
                      ]),
                      _: 1
                    })
                  ], 8, _hoisted_7)
                ])
              ])
            ])
          }
        : undefined
    ]), 1032, ["action", "show-file-list", "accept", "list-type", "file-list"]),
    _createVNode(_component_el_dialog, {
      modelValue: dialogVisible.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (dialogVisible.value = $event)),
      width: "48%",
      "destroy-on-close": true,
      center: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          style: {"width":"100%"},
          src: dialogImageUrl.value
        }, null, 8, _hoisted_8)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})