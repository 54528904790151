
import { defineComponent, PropType } from 'vue'
import { RouteRecordRaw } from 'vue-router'

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<RouteRecordRaw[]>,
      required: true
    }
  }
})
