import SvgIcon from '@/components/svg-icon'
import { App } from 'vue'

const svgPlugin: any = (app: App) => {
  // 全量引入图标
  const requireAll = (requireContext: __WebpackModuleApi.RequireContext) =>
    requireContext.keys().map(requireContext)
  const ctx = require.context('@/assets/icons/svg', false, /\.svg$/)
  requireAll(ctx)
  app.component('SvgIcon', SvgIcon)
}

export default function loadComponent(app: App): void {
  app.use(svgPlugin)
}
