import { MutationTree } from 'vuex'
import { UserState } from './state'
import { UserMutationTypes } from './mutation-types'
import { UserInfoType } from '@/types/user'
import { setToken, setUserId } from '@/utils/cookies'
import storage from '@/utils/localStorage'

export type Mutations<S = UserState> = {
  [UserMutationTypes.SET_USERINFO](state: S, userInfo: UserInfoType): void
  [UserMutationTypes.SET_TOKEN](state: S, token: string): void
  [UserMutationTypes.SET_USERID](state: S, userId: string): void
  [UserMutationTypes.SET_ACCOUNT](state: S, account: string): void
  [UserMutationTypes.SET_LOGIN_SUCC](state: S, isLogin: boolean): void
  [UserMutationTypes.SET_SHOW_LOGIN_DIALOG](state: S, showLoginDialog: boolean): void
  [UserMutationTypes.SET_IS_KEEP_LOGIN](state: S, isKeepLogin: boolean): void
}

export const mutations: MutationTree<UserState> & Mutations = {
  /** 是否打开登录弹窗 */
  [UserMutationTypes.SET_SHOW_LOGIN_DIALOG](state: UserState, showLoginDialog: boolean) {
    state.showLoginDialog = showLoginDialog
  },
  /** 用户详细数据 */
  [UserMutationTypes.SET_USERINFO](state: UserState, userInfo: UserInfoType) {
    state.userInfo = userInfo
  },
  /** token */
  [UserMutationTypes.SET_TOKEN](state: UserState, token: string) {
    setToken(token)
    state.user.token = token
  },
  /** 用户ID */
  [UserMutationTypes.SET_USERID](state: UserState, userId: string) {
    setUserId(userId)
    state.user.userId = userId
  },
  /** 账号 */
  [UserMutationTypes.SET_ACCOUNT](state: UserState, account: string) {
    state.user.account = account
  },
  /** 登录成功并设置用户登陆状态 */
  [UserMutationTypes.SET_LOGIN_SUCC](state: UserState, isLogin: boolean) {
    state.user.isLogin = isLogin
  },
  /** 登录成功并设置用户登陆状态 */
  [UserMutationTypes.SET_IS_KEEP_LOGIN](state: UserState, isKeepLogin: boolean) {
    storage.setItem('isKeepLogin', isKeepLogin)
    state.user.isKeepLogin = isKeepLogin
  }
}
