import md5 from 'md5'
import { stringify } from 'qs'

/**
 * 过滤赋值为空的字段
 *
 * @param data data
 */
export function filterDataEmpty(data: any) {
  for (const key in data) {
    if (data[key] === '') {
      delete data[key]
    }
  }
}

/**
 * 获取时间戳（秒）
 */
export function getTimestamp(): number {
  return (new Date()).valueOf()
}

/**
 * 获取随机数(0-10000]
 */
export function getRandom(): number {
  return (Math.random() * 1000000) << 0
}

/**
 * 获取指定位数随机数
 *
 * @param len 随机数长度
 */
 export function randomNum(len: number): string {
  let salt = ''
  for (let i = 0; i < len; i++) {
    let tmp = String(Math.floor(Math.random() * 10))
    if (!tmp) {
      tmp = '2'
    }
    salt += tmp
  }
  return salt
}

/**
 * 参数签名(object)
 *
 * @param data data
 * @param secretKey secretKey
 */
export function getSignedData(data: any, secretKey: string): string {
  const keys: any = Object.keys(data).sort()
  let sign = ''
  const sortData: any = {}
  keys.forEach((key: string) => {
    let value = data[key]
    if (value === 'object') {
      value = JSON.stringify(value)
    }
    if (Array.isArray(value)) {
      value = JSON.stringify(value)
    }
    if (!(value === undefined || value === null || value === '')) {
      sortData[key] = value
    }
  })
  let source = stringify(sortData)
  source = source + '&' + secretKey
  sign = md5(source).toLowerCase()
  return sign
}
