import { ElMessage, ElMessageBox, ElNotification, MessageParamsTyped, NotificationParams } from 'element-plus/lib'

export default {
  // 消息提示
  msg(content: MessageParamsTyped | undefined) {
    ElMessage.info(content)
  },
  // 错误消息
  msgError(content: MessageParamsTyped | undefined) {
    ElMessage.error(content)
  },
  // 成功消息
  msgSuccess(content: MessageParamsTyped | undefined) {
    ElMessage.success(content)
  },
  // 警告消息
  msgWarning(content: MessageParamsTyped | undefined) {
    ElMessage.warning(content)
  },
  // 确认窗体
  confirm(content: string) {
    return ElMessageBox.confirm(content, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  // 通知提示
  notify(params: NotificationParams) {
    ElNotification(params)
  },
  // 错误通知
  notifyError(params: NotificationParams) {
    ElNotification.error(params)
  },
  // 成功通知
  notifySuccess(params: NotificationParams) {
    ElNotification.success(params)
  },
  // 警告通知
  notifyWarning(params: NotificationParams) {
    ElNotification.warning(params)
  }
}
