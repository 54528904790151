import { App } from 'vue'
import {
  ElButton,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInput,
  ElLink,
  ElRadioGroup,
  ElRadio,
  ElTabPane,
  ElTabs,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElTooltip,
  ElCard,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElDrawer,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElScrollbar,
  ElIcon,
  ElSwitch,
  ElColorPicker,
  ElDialog,
  ElBacktop,
  ElSelect,
  ElOption,
  ElSteps,
  ElStep,
  ElDatePicker,
  ElEmpty,
  ElUpload
} from 'element-plus/lib/components'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'

const components = [
  ElButton,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInput,
  ElLink,
  ElRadioGroup,
  ElRadio,
  ElTabPane,
  ElTabs,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElTooltip,
  ElCard,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElDrawer,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElScrollbar,
  ElIcon,
  ElSwitch,
  ElColorPicker,
  ElDialog,
  ElBacktop,
  ElSelect,
  ElOption,
  ElSteps,
  ElStep,
  ElDatePicker,
  ElEmpty,
  ElUpload
]

export default function registerElement(app: App): void {
  for (const component of components) {
    app.component(component.name, component)
  }

  // 注册全局组件element-plus/icons
  Object.keys(Icons).forEach(key => {
    app.component(Icons[key as keyof typeof Icons].name, Icons[key as keyof typeof Icons])
  })
}

export function loadComponent(app: App): void {
  app.use(registerElement)
}
