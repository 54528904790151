export enum UserActionTypes {
  ACTION_LOGIN = 'ACTION_LOGIN',
  ACTION_LOGOUT = 'ACTION_LOGOUT',
  ACTION_CLEAR_INFO = 'ACTION_CLEAR_INFO',
  ACTION_UPDATE_LOCAL_USER = 'ACTION_UPDATE_LOCAL_USER',
  ACTION_VERIFY_TOKEN = 'ACTION_VERIFY_TOKEN',
  ACTION_GET_IDENTIFY_CODE = "ACTION_GET_IDENTIFY_CODE",
  ACTION_VALIDATE_IDENTIFY_CODE = "ACTION_VALIDATE_IDENTIFY_CODE",
  ACTION_REFRESH_TOKEN = "ACTION_REFRESH_TOKEN",
  ACTION_CODE_UPDATE_Pwd = "ACTION_CODE_UPDATE_Pwd"
}
