import { IdentifyCodeInfoType, LoginInfoType, LogoutInfoType, UpdatePwdType, ValidateCodeInfoType } from '@/types/user'
import request from '../utils/request'
import { loginURL } from './config'
import { filterDataEmpty } from './utils'

/**
 * 手机号+密码登陆
 * @param pf  登录平台 1:android 2:ios 3:web 4:pc
 * @param version 客户端版本号
 * @param evkAppId 业务ID
 * @param phone 手机号
 * @param pwd 密码
 * @api地址 http://yapi.xf.io/project/63/interface/api/2034
 * @returns
 */
export function login(params: LoginInfoType) {
  filterDataEmpty(params)
  return request({
    url: `${loginURL}/v1/common/user/loginByPhoneAndPwd`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

/**
 * 用户退出登陆
 * @param token
 * @param userId
 * @param pf
 * @param version
 * @api http://yapi.xf.io/project/63/interface/api/5247
 * @returns
 */
export function logout(info: LogoutInfoType) {
  return request({
    url: `${loginURL}/v1/common/user/logout`,
    method: 'get',
    headers: {
      token: info.token,
      userId: info.userId
    },
    params: {
      pf: info.pf,
      version: info.version
    }
  })
}

/**
 * 验证token有效
 * @param token
 * @param userId
 * @param pf
 * @param version
 * @api http://yapi.xf.io/project/63/interface/api/5283
 * @returns
 */

export function verifyToken(token: string, userId: string, pf: number, version: string) {
  return request({
    url: `${loginURL}/v1/common/user/verifyToken`,
    method: 'get',
    headers: {
      token: token,
      userId: userId
    },
    params: {
      pf: pf,
      version: version
    }
  })
}

/**
 * 根据类型发送验证码
 * @param params
 * @returns
 * @api http://yapi.xf.io/project/63/interface/api/2070
 */
export function getIdentifyCode(params: IdentifyCodeInfoType) {
  return request({
    url: `${loginURL}/v1/common/user/sendPhoneVerificationCodeByType`,
    method: 'get',
    params
  })
}

/**
 * 验证验证码
 * @param params
 * @returns
 */
export function validateIdentifyCode(params: ValidateCodeInfoType) {
  return request({
    url: `${loginURL}/v1/common/user/loginByVerificationCode`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

export function refreshToken(token: string, userId: string, pf: number, version: string, tokenValidityMs: number) {
  return request({
    url: `${loginURL}/v1/common/user/refreshToken`,
    method: 'get',
    headers: {
      'Content-Type': 'application/jsoncharset=UTF-8',
      token: token,
      userId: userId,
      pf: pf,
      version: version
    },
    params: {
      tokenValidityMs
    }
  })
}

export function updatePwd(params: UpdatePwdType) {
  return request({
    url: `${loginURL}/v1/common/user/updatePwdByPhoneVerificationCode`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}
