import { UserInfoType, UserStateType } from '@/types/user'
import { getToken, getUserId } from '@/utils/cookies'
import storage from '@/utils/localStorage'

export interface UserState {
  user: UserStateType
  userInfo: UserInfoType
  showLoginDialog: boolean
}

export const state: UserState = {
  user: {
    token: getToken() || '',
    userId: getUserId() || '',
    account: '',
    isForce: false,
    isNonPsw: false,
    isLogin: false,
    isKeepLogin: storage.getItem('isKeepLogin') || false,
    hidLogSevenDayTime: ''
  },
  userInfo: {
    area: '',
    areaCode: '',
    birthDay: '',
    createTime: '',
    des: '',
    email: '',
    evkAppId: '',
    evkFirstCreate: false, //唤境平台首次创建
    firstCreate: false, //星飞用户平台首次创建
    flushTime: '',
    hot: 0,
    id: '',
    introduction: '',
    isReal: 1,
    lastLoginTime: '',
    nickname: '',
    phone: '',
    portrait: '',
    realName: '',
    sex: 0,
    token: '',
    uid: '',
    updateTime: '',
    userName: ''
  },
  showLoginDialog: false
}
