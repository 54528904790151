import { getAliOssConfig, multipartUpload } from '@/api/aliOss'
import { OssConfigType } from '@/api/types/aliOss'
import { MultipartUploadResult } from 'ali-oss'
import { ref } from 'vue'

export function useUpload() {
  const ossConfig = ref<OssConfigType>()
  const progressNum = ref<number>(0)
  const uploadStatus = ref<String>('')

  const getAilOssConfig = (fileType: number) => {
    return new Promise((resolve: (value: OssConfigType) => void) => {
      getAliOssConfig(fileType).then(res => {
        ossConfig.value = res.data.credentials
        resolve(res.data.credentials)
      }).catch(err => {
        console.error(err)
      })
    })
  }

  // 使用阿里oss分片上传
  async function aliUpload(fileType: number, file: File) {
    await getAilOssConfig(fileType)
    if (!ossConfig.value) return
    const config = Object.assign({}, ossConfig.value, {
      cname: true,
      endpoint: ossConfig.value.cdnUrl
    })
    return new Promise((resolve: (value: MultipartUploadResult) => void, reject) => {
      uploadStatus.value = 'start'
      multipartUpload(config, file, (progress) => {
        uploadStatus.value = 'loading'
        progressNum.value = Number((progress * 100).toFixed(2))
      }).then(res => {
        uploadStatus.value = 'success'
        resolve(res)
      }).catch(err => {
        uploadStatus.value = 'error'
        reject(err)
      })
    })
  }
  return { getAilOssConfig, aliUpload, uploadStatus, progressNum }
}
