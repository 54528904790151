import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-65228f75")
const _hoisted_1 = ["data-index"]
const _hoisted_2 = { class: "waterfall-loading" }
const _hoisted_3 = { class: "dot-wrapper" }
const _hoisted_4 = { class: "waterfall-over-message" }
_popScopeId()

import {
  computed,
  nextTick,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  Ref,
  ref,
  toRefs,
  watch
} from 'vue'

type Column = number[]


export default _defineComponent({
  props: {
    columnWidth: { type: Number, required: false, default: 400 },
    items: { type: Array, required: true },
    gap: { type: Number, required: false, default: 0 },
    rtl: { type: Boolean, required: false, default: false },
    ssrColumns: { type: Number, required: false, default: 0 },
    dotsColor: { type: String, required: false, default: '' },
    dotsNum: { type: Number, required: false, default: 5 },
    distanceToScroll: { type: Number, required: false, default: 200 },
    isLoading: { type: Boolean, required: false, default: false },
    isOver: { type: Boolean, required: false, default: false },
    overText: { type: String, required: false, default: '呀，被看光了！' }
  } as unknown as undefined,
  emits: ["redraw", "redraw-skip", "scroll-reach-bottom"] as unknown as undefined,
  setup(__props: {
    columnWidth?: number
    items: unknown[]
    gap?: number
    rtl?: boolean
    ssrColumns?: number
    dotsColor?: string
    dotsNum?: number
    distanceToScroll?: number
    isLoading?: boolean
    isOver?: boolean
    overText?: string
  }, { emit }: { emit: ({
  (event: 'redraw'): void
  (event: 'redraw-skip'): void
  (event: 'scroll-reach-bottom'): void
}), expose: any, slots: any, attrs: any }) {

const props = __props


const {
  columnWidth,
  items,
  gap,
  rtl,
  ssrColumns,
  distanceToScroll,
  isLoading,
  isOver
} = toRefs(props)
const columns = ref<Column[]>([])
const wall = ref<HTMLDivElement>() as Ref<HTMLDivElement>



function columnCount(): number {
  const count = Math.floor(
    (wall.value.getBoundingClientRect().width + gap.value) /
      (columnWidth.value + gap.value)
  )
  return count > 0 ? count : 1
}

function createColumns(count: number): Column[] {
  return [...new Array(count)].map(() => [])
}

if (ssrColumns.value > 0) {
  const newColumns = createColumns(ssrColumns.value)
  items.value.forEach((_: unknown, i: number) =>
    newColumns[i % ssrColumns.value].push(i)
  )
  columns.value = newColumns
}

async function fillColumns(itemIndex: number) {
  if (itemIndex >= items.value.length) {
    return
  }
  await nextTick()
  const columnDivs = [...wall.value.children] as HTMLDivElement[]
  if (rtl.value) {
    columnDivs.reverse()
  }
  const target = columnDivs.reduce((prev, curr) =>
    curr.getBoundingClientRect().height < prev.getBoundingClientRect().height
      ? curr
      : prev
  )
  columns.value[+target.dataset.index!].push(itemIndex)
  await fillColumns(itemIndex + 1)
}

async function redraw(force = false) {
  if (columns.value.length === columnCount() && !force) {
    emit('redraw-skip')
    return
  }
  columns.value = createColumns(columnCount())
  const scrollY = window.scrollY
  await fillColumns(0)
  window.scrollTo({ top: scrollY })
  emit('redraw')
}

const resizeObserver = new ResizeObserver(() => redraw())

// 加载状态
const actualLoading = computed(() => {
  return isLoading.value || !items.value.length
})

onMounted(() => {
  redraw()
  resizeObserver.observe(wall.value)
  scrollElement.addEventListener('scroll', scrollFn)
})

onBeforeUnmount(() => resizeObserver.unobserve(wall.value))

// 兼容滚动事件绑定在 window 上，
// 并且页面被 keep-alive 缓存时滚动穿越的情形
// (a 页面绑定滚动被缓存，b 页面滚动会影响 a 页面的监听)
let isActive = true
onActivated(() => (isActive = true))
onDeactivated(() => (isActive = false))

// 滚动
let scrollElement: Window | HTMLElement = window
let body = document.documentElement || document.body
let scrollTimeoutHandle: any
const scrollFn = (): void => {
  if (actualLoading.value || isOver.value || !isActive) return
  const [scrollHeight, scrollTop, clientHeight] = [
    body.scrollHeight,
    body.scrollTop,
    body.clientHeight
  ]
  if (scrollHeight - scrollTop - clientHeight <= distanceToScroll.value) {
    clearTimeout(scrollTimeoutHandle)
    scrollTimeoutHandle = setTimeout(() => {
      emit('scroll-reach-bottom')
    }, 200)
  }
}

watch([items, rtl], () => redraw(true))
watch([columnWidth, gap], () => redraw())

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: wall,
      class: "waterfall-wrapper",
      style: _normalizeStyle({ display: 'flex', gap: `${_unref(gap)}px` })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.value, (column, columnIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: columnIndex,
          class: "masonry-column",
          "data-index": columnIndex,
          style: _normalizeStyle({ height: ['-webkit-max-content', '-moz-max-content', 'max-content'],
            gap: `${_unref(gap)}px`})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column, (itemIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: itemIndex,
              class: "masonry-item"
            }, [
              _renderSlot(_ctx.$slots, "default", {
                item: _unref(items)[itemIndex]
              })
            ]))
          }), 128))
        ], 12, _hoisted_1))
      }), 128))
    ], 4),
    (_unref(actualLoading) && !_unref(isOver))
      ? _renderSlot(_ctx.$slots, "loading", { key: 0 }, () => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(new Array(__props.dotsNum), (item, idx) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "dot",
                  style: _normalizeStyle('background-color:' + __props.dotsColor),
                  key: idx
                }, null, 4))
              }), 128))
            ])
          ])
        ])
      : _createCommentVNode("", true),
    (_unref(isOver))
      ? _renderSlot(_ctx.$slots, "footer", { key: 1 }, () => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(__props.overText), 1)
        ])
      : _createCommentVNode("", true)
  ]))
}
}

})