const env = process.env.VUE_APP_ENV || 'development'

console.log('-------process.env.VUE_APP_ENV', process.env.VUE_APP_ENV, env)
console.log('-------process.env.NODE_ENV', process.env.NODE_ENV)

const dev = {
  // TODO 跟服务端最终确认各个环境变量值
  // 因为只需用生产环境数据即可
  baseURL: 'https://api.evkworld.cn',
  // 因只有测试环境
  adminURL: 'https://test-app.evkland.cn/box-admin',
  loginURL: 'https://dev-duc.evkland.cn',
  developerURL: 'https://dev-developer.evkland.cn',
  aliOssURL: 'https://dev-developer.evkland.cn',
  // 因为只需用开发环境数据即可
  dramaOperatorURL: 'https://dev-developer.evkland.cn/admin'
}

const prop = {
  // TODO 跟服务端最终确认各个环境变量值
  baseURL: 'https://api.evkworld.cn',
  adminURL: 'https://test-app.evkland.cn/box-admin',
  loginURL: 'https://duc.evkworld.cn',
  developerURL: 'https://api.evkworld.cn',
  aliOssURL: 'https://dev-developer.evkland.cn',
  dramaOperatorURL: 'https://dev-developer.evkland.cn/admin'
}

let config = dev

switch (env) {
  case 'development':
    config = dev
    break
  case 'production':
    config = prop
    break
}

export const baseURL = config.baseURL
export const adminURL = config.adminURL
export const loginURL = config.loginURL
export const developerURL = config.developerURL
export const aliOssURL = config.aliOssURL
export const dramaOperatorURL = config.dramaOperatorURL
