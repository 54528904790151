<template>
  <div id="home_icp">
    <header-nav background="#272727" background-color="#272727" text-color="#FFFFFF" active-text-color="#409eff"
      :routes="routes">
      <el-button v-if="!isLogin" class="login-btn" type="text" style="color: #FFC400"
        @click="handleShowLogin">登录/注册</el-button>
      <div v-else class="avatar">
        <el-dropdown class="right-menu-item hover-effect" trigger="hover">
          <div class="avatar__wrapper">
            <img class="avatar__wrapper--img" :src="avatarUrl" @error="imgError" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <router-link to="/">
                <el-dropdown-item>
                  <el-tooltip effect="dark" :content="nickname" placement="bottom">
                    <span class="avatar__wrapper--name">
                      <el-icon class="avatar__wrapper--icon">
                        <avatar />
                      </el-icon>
                      {{ nickname }}
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
                <el-dropdown-item>首页</el-dropdown-item>
              </router-link>
              <el-dropdown-item divided @click="logout">
                <span style="display:block;">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </header-nav>
    <div class="main">
      <router-view />
    </div>
    <login :show="showLoginDialog" @close="handleCloseLogin"></login>
    <xy-footer></xy-footer>
  </div>
</template>

<script lang='ts'>
import HeaderNav from '@/components/header-nav'
import XyFooter from '@/views/components/xy-footer/index.vue'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  Ref,
  toRefs,
  watchEffect,
  defineAsyncComponent
} from 'vue'
import { RouteRecordRaw } from 'vue-router'
import router from '@/router'
import { useStore } from '@/store'
import { UserMutationTypes } from '@/store/modules/user/mutation-types'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { LogoutInfoType } from '@/types/user'
import { ElMessage } from 'element-plus/lib/components'
import { getToken } from '@/utils/cookies'
import avatar from '@/assets/img/avatar.png'

interface State {
  routes: Ref<RouteRecordRaw[]>
  isLogin: Ref<boolean>
  showLoginDialog: Ref<boolean>
  avatarUrl: Ref<string>
  nickname: Ref<string>
  handleShowLogin: () => void
  handleCloseLogin: () => void
  logout: () => void
  imgError: () => void
}
export default defineComponent({
  components: {
    HeaderNav,
    XyFooter,
    Login: defineAsyncComponent(() => import('./views/components/login/index.vue'))
  },
  setup(): State {
    const store = useStore()

    const routes = computed(() => {
      return router.options.routes
    })
    const state = reactive({
      avatarUrl: store.state.user.userInfo.portrait
    })

    const showLoginDialog = computed(() => {
      return store.state.user.showLoginDialog
    })

    const isLogin = computed(() => {
      return store.state.user.user.isLogin
    })

    watchEffect(() => {
      state.avatarUrl = store.state.user.userInfo.portrait
    })

    const nickname = computed(() => {
      return store.state.user.userInfo.nickname
    })

    const token = computed(() => {
      return getToken()
    })

    onMounted(() => {
      document.addEventListener('contextmenu', _injectDisabledContextMenu, false)
      if (token.value) {
        store.dispatch(UserActionTypes.ACTION_UPDATE_LOCAL_USER, undefined)
      }
    })

    const _injectDisabledContextMenu = (event: MouseEvent) => {
      // 取消右键点击事件的默认行为
      event.preventDefault()
      event.stopPropagation()
    }

    const imgError = () => {
      state.avatarUrl = avatar
    }

    const handleShowLogin = () => {
      store.commit(UserMutationTypes.SET_SHOW_LOGIN_DIALOG, true)
    }

    const handleCloseLogin = () => {
      store.commit(UserMutationTypes.SET_SHOW_LOGIN_DIALOG, false)
    }

    function logout() {
      const logoutInfo: LogoutInfoType = {
        token: store.state.user.user.token,
        userId: store.state.user.user.userId,
        pf: 4,
        version: 'web'
      }
      store
        .dispatch(UserActionTypes.ACTION_LOGOUT, logoutInfo)
        .then((res: any) => {
          console.log(res)
          ElMessage.success('退出成功')
        })
    }

    onBeforeUnmount(() => {
      document.removeEventListener('contextmenu', _injectDisabledContextMenu)
    })

    return {
      routes,
      isLogin,
      showLoginDialog,
      nickname,
      ...toRefs(state),
      handleShowLogin,
      handleCloseLogin,
      logout,
      imgError
    }
  }
})
</script>
<style lang='scss' scoped>
img[src=""],
/* src为空 */
img:not([src]) {
  /* 没有src属性 */
  visibility: hidden;
}

.login-btn {
  margin-left: 5px;
  font-size: 16px;

  &:hover {
    color: #ffffff !important;
  }
}

@include b(main) {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

@include b(avatar) {
  margin-left: 25px;

  @include e(wrapper) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    cursor: pointer;

    @include m(name) {
      font-weight: 500;
      color: #5cc1ff;
      font-size: 14px;
      text-align: center;
      @include ellipsis(1);
    }

    @include m(icon) {
      font-size: 15px;
      vertical-align: text-top;
    }

    @include m(img) {
      border-radius: 50%;
      border: 2px solid #5f74a4;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
