import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@/store'
import { state, UserState } from './state'
import { Mutations } from './mutations'
import { UserMutationTypes } from './mutation-types'
import { UserActionTypes } from './action-types'
import { IdentifyCodeInfoType, LoginInfoType, LogoutInfoType, UpdatePwdType, ValidateCodeInfoType } from '@/types/user'
import { getIdentifyCode, login, logout, refreshToken, updatePwd, validateIdentifyCode, verifyToken } from '@/api/login'
import { getToken, getUserId, removeToken } from '@/utils/cookies'
import storage from '@/utils/localStorage'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<UserState, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    loginInfo: LoginInfoType
  ): Promise<{}>,
  [UserActionTypes.ACTION_LOGOUT](
    { dispatch }: AugmentedActionContext,
    logoutInfo: LogoutInfoType
  ): Promise<{}>,
  [UserActionTypes.ACTION_CLEAR_INFO](
    { commit }: AugmentedActionContext
  ): void,
  [UserActionTypes.ACTION_UPDATE_LOCAL_USER](
    { commit, dispatch }: AugmentedActionContext
  ): void,
  [UserActionTypes.ACTION_VERIFY_TOKEN](
    { dispatch }: AugmentedActionContext,
    info: { token: string, userId: string, pf: number, version: string }
  ): Promise<{}>,
  [UserActionTypes.ACTION_GET_IDENTIFY_CODE](
    { dispatch }: AugmentedActionContext,
    identifyCodeInfo: IdentifyCodeInfoType
  ): Promise<{}>,
  [UserActionTypes.ACTION_VALIDATE_IDENTIFY_CODE](
    { commit }: AugmentedActionContext,
    validateCodeInfo: ValidateCodeInfoType
  ): Promise<{}>,
  [UserActionTypes.ACTION_REFRESH_TOKEN](
    { commit, dispatch }: AugmentedActionContext,
  ): Promise<{}>,
  [UserActionTypes.ACTION_CODE_UPDATE_Pwd](
    { commit }: AugmentedActionContext,
    updatePwdInfo: UpdatePwdType
  ): Promise<{}>
}

export const actions: ActionTree<UserState, RootState> & Actions = {
  /** 用户登录 */
  [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    loginInfo: LoginInfoType
  ) {
    return new Promise((resolve, reject) => {
      const params = { ...loginInfo }
      login(params).then(res => {
        if (res.status !== 1) {
          throw res
        }

        commit(UserMutationTypes.SET_USERINFO, res.data.user)
        commit(UserMutationTypes.SET_TOKEN, res.data.user.token)
        commit(UserMutationTypes.SET_USERID, res.data.user.id)
        commit(UserMutationTypes.SET_ACCOUNT, params.phone)
        commit(UserMutationTypes.SET_LOGIN_SUCC, true)

        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /** 用户退出 */
  [UserActionTypes.ACTION_LOGOUT](
    { dispatch }: AugmentedActionContext,
    logoutInfo: LogoutInfoType
  ) {
    return new Promise((resolve, reject) => {

      logout(logoutInfo).then(res => {
        if (res.status !== 1) {
          throw res
        }
        dispatch(UserActionTypes.ACTION_CLEAR_INFO)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /** 更新本地用户数据 */
  [UserActionTypes.ACTION_UPDATE_LOCAL_USER](
    { commit, dispatch }: AugmentedActionContext
  ) {
    const info = {
      token: getToken(),
      userId: getUserId(),
      pf: 3,
      version: 'web'
    }
    dispatch(UserActionTypes.ACTION_VERIFY_TOKEN, info).then(res => {
      if (res.status === 1 && res.data.user) {
        commit(UserMutationTypes.SET_USERINFO, res.data.user)
        commit(UserMutationTypes.SET_TOKEN, res.data.user.token)
        commit(UserMutationTypes.SET_USERID, res.data.user.id)
        commit(UserMutationTypes.SET_LOGIN_SUCC, true)
        const isKeepLogin = storage.getItem('isKeepLogin')
        if (isKeepLogin) {
          dispatch(UserActionTypes.ACTION_REFRESH_TOKEN).then(res => {
            if (res.status !== 1) {
              dispatch(UserActionTypes.ACTION_CLEAR_INFO)
              return
            }
          })
        }
        return
      }
      dispatch(UserActionTypes.ACTION_CLEAR_INFO)
    })
  },

  /** 刷新token */
  [UserActionTypes.ACTION_REFRESH_TOKEN](
    { commit, dispatch }: AugmentedActionContext
  ) {
    const tokenValidityMs = 1000 * 60 * 60 * 60 * 24 * 7
    return new Promise((resolve, reject) => {
      refreshToken(getToken() as string, getUserId() as string, 3, 'web', tokenValidityMs).then(res => {
        if (res.status !== 1) {
          throw res
        }
        commit(UserMutationTypes.SET_TOKEN, res.data.token)
        commit(UserMutationTypes.SET_USERID, res.data.userId)
        commit(UserMutationTypes.SET_LOGIN_SUCC, true)
        resolve(res)
      }).catch(error => {
        dispatch(UserActionTypes.ACTION_CLEAR_INFO)
        console.error(error)
        reject(error)
      })
    })
  },

  /** 验证token */
  [UserActionTypes.ACTION_VERIFY_TOKEN](
    { dispatch }: AugmentedActionContext,
    info: { token: string, userId: string, pf: number, version: string }
  ) {
    return new Promise((resolve, reject) => {
      verifyToken(info.token, info.userId, info.pf, info.version).then(res => {
        resolve(res)
      }).catch(error => {
        dispatch(UserActionTypes.ACTION_CLEAR_INFO)
        reject(error)
      })
    })
  },

  /** 获取验证码 */
  [UserActionTypes.ACTION_GET_IDENTIFY_CODE](
    { commit }: AugmentedActionContext,
    identifyCodeInfo: IdentifyCodeInfoType
  ) {
    return new Promise((resolve, reject) => {
      const params = { ...identifyCodeInfo }
      getIdentifyCode(params).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /** 验证码登录 */
  [UserActionTypes.ACTION_VALIDATE_IDENTIFY_CODE](
    { commit }: AugmentedActionContext,
    validateCodeInfo: ValidateCodeInfoType
  ) {
    const params = { ...validateCodeInfo }
    return new Promise((resolve, reject) => {
      validateIdentifyCode(params).then(res => {
        if (res.status !== 1) {
          throw res
        }

        commit(UserMutationTypes.SET_USERINFO, res.data.user)
        commit(UserMutationTypes.SET_TOKEN, res.data.user.token)
        commit(UserMutationTypes.SET_USERID, res.data.user.id)
        commit(UserMutationTypes.SET_ACCOUNT, params.phone)
        commit(UserMutationTypes.SET_LOGIN_SUCC, true)

        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /** 清除用户登录态以及相关数据 */
  [UserActionTypes.ACTION_CLEAR_INFO](
    { commit }: AugmentedActionContext
  ) {
    removeToken()
    commit(UserMutationTypes.SET_USERINFO, state.userInfo)
    commit(UserMutationTypes.SET_TOKEN, '')
    commit(UserMutationTypes.SET_USERID, '')
    commit(UserMutationTypes.SET_ACCOUNT, '')
    commit(UserMutationTypes.SET_LOGIN_SUCC, false)
  },

  /** 通过短信验证码更新密码 */
  [UserActionTypes.ACTION_CODE_UPDATE_Pwd](
    { commit }: AugmentedActionContext,
    updatePwdInfo: UpdatePwdType
  ) {
    const params = { ...updatePwdInfo }
    return new Promise((resolve, reject) => {
      updatePwd(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
        console.error(err)
      })
    })
  }
}
