
import { onMounted } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { watch } from 'vue'

export default {
  name: 'BackTop',
  components: {},
  props: {},
  setup() {
    const route = useRoute()

    watch(
      () => route.path,
      () => {
        scrollToTop()
      }
    )

    let el: any = null
    let target = '.pages-content-scroll'

    const scrollToTop = () => {
      let step = 0
      if (el) {
        let interval = setInterval(() => {
          if (el.scrollTop <= 0) {
            clearInterval(interval)
            return
          }
          step += 10
          el.scrollTop -= step
        }, 20)
      }
    }

    const init = () => {
      el = document.documentElement
      if (target) {
        el = document.querySelector(target)
        if (!el) {
          throw new Error(`target is not existed: ${target}`)
        }
      }
    }

    onMounted(() => {
      init()
    })

    return {
      el,
      target
    }
  }
}
