/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Layout, Header, Content, Footer } from './layout'
import BackTop from './backTop/index.vue'
import { UploadImage } from './upload'
export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install: (app: any) => {
    app.component('XyLayout', Layout)
    app.component('XyHeader', Header)
    app.component('XyContent', Content)
    app.component('YFooter', Footer)
    app.component('BackTop', BackTop)
    app.component('UploadImage', UploadImage)
  }
}
