interface storageType {
  getItem(key: string): any
  setItem(key: string, value: any): void
  removeItem(key: string): void
  clear(): void
}

const storage: storageType = {
  getItem(key: string) {
    return JSON.parse(localStorage.getItem(key) as string)
  },
  setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  removeItem(key: string) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  }
}

export default storage
