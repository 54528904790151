<template>
  <header
    class="xy-header"
    :class="{'xy-header--fixed': fixed}">
    <slot></slot>
  </header>
</template>
<script>
export default {
  name: 'XyHeader',
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@include b(xy-header) {
  background-color: $background-color-primary;
  @include m(fixed) {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
  }
}
</style>
