import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import '@/styles/index.scss'
import '@/styles/element/element-variables.scss'
import { loadAllPlugins } from '@/plugins'
import XyComponents from '@/components'
import { ElLoading } from 'element-plus/lib/components'
import MasonryWall from '@/lib/v3-masonry-wall'
import utils from '@/utils/index'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(XyComponents)
app.use(MasonryWall)
app.use(ElLoading)
app.use(utils)
// 加载所有本地自定义插件
loadAllPlugins(app)

app.mount('#home_icp')
