<template>
    <footer class="y-footer">
        <slot></slot>
    </footer>
</template>

<script lang="ts">
export default {
  name: 'YFooter'
}
</script>

<style lang="scss" scoped>
@include b(y-footer) {
  position: fixed;
  bottom: 0px;
  width: 100%;
}
</style>
