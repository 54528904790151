<template>
    <div class="pages-content-scroll">
        <el-backtop
            target=".pages-content-scroll"
            :visibility-height="400"
            :bottom="100"
            :right="30"
        >
            <div class="backtop">
                <svg-icon name="rocket" className="rocket"/>
            </div>
        </el-backtop>
        <slot></slot>
    </div>
</template>

<script lang='ts'>
import { onMounted } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { watch } from 'vue'

export default {
  name: 'BackTop',
  components: {},
  props: {},
  setup() {
    const route = useRoute()

    watch(
      () => route.path,
      () => {
        scrollToTop()
      }
    )

    let el: any = null
    let target = '.pages-content-scroll'

    const scrollToTop = () => {
      let step = 0
      if (el) {
        let interval = setInterval(() => {
          if (el.scrollTop <= 0) {
            clearInterval(interval)
            return
          }
          step += 10
          el.scrollTop -= step
        }, 20)
      }
    }

    const init = () => {
      el = document.documentElement
      if (target) {
        el = document.querySelector(target)
        if (!el) {
          throw new Error(`target is not existed: ${target}`)
        }
      }
    }

    onMounted(() => {
      init()
    })

    return {
      el,
      target
    }
  }
}
</script>

<style scoped lang="scss">
.pages-content-scroll {
  /*必须撑满整个屏幕*/
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}
.backtop {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  font-size: 20px;
}
.rocket {
  width: 40px;
  height: 36px;
}
</style>
