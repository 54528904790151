
import { computed, PropType } from 'vue'
import NavList from './nav-list.vue'
import { RouteRecordRaw, useRoute } from 'vue-router'

export default {
  props: {
    background: {
      type: String as PropType<string>
    },
    routes: {
      type: Array as PropType<RouteRecordRaw[]>,
      required: true
    }
  },
  components: {
    NavList
  },
  setup(props: Readonly<{}>) {
    const route = useRoute()

    const activeIndex = computed(() => {
      return route.path
    })
    const handleMenuSelect = (key: string, keyPath: string[]) => {}
    return {
      activeIndex,
      handleMenuSelect
    }
  }
}
