import { createStore, createLogger } from 'vuex'
import { store as user, UserStore, UserState } from '@/store/modules/user'
import { store as material, MaterialStore, MaterialState } from '@/store/modules/material'

export interface RootState {
  user: UserState
  material: MaterialState
}

export type Store = UserStore<Pick<RootState, 'user'>> & MaterialStore<Pick<RootState, 'material'>>

const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

export const store = createStore({
  plugins,
  modules: {
    user,
    material
  }
})

export function useStore(): Store {
  return store as Store
}
