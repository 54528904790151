import { MutationTree } from 'vuex'
import { IMaterialPageType, MaterialState } from './state'
import { MaterialMutationTypes } from './mutation-types'

export type Mutations<S = MaterialState> = {
  [MaterialMutationTypes.SET_MATERIAL_SOURCE_PAGE](state: S, pageInfo: IMaterialPageType): void
}

export const mutations: MutationTree<MaterialState> & Mutations = {
  [MaterialMutationTypes.SET_MATERIAL_SOURCE_PAGE](state: MaterialState, pageInfo: IMaterialPageType) {
    state.page = pageInfo
  }
}
