<template>
    <div class="nav-list">
        <!-- 因为有子集和无子集渲染html标签不一样，所以要分为两种情况
        情况一：有子集的情况：-->
        <template v-for="(item, index) in list">
            <el-sub-menu
                :key="index"
                :index="item.path"
                popper-append-to-body
                v-if="item.children && item.children.length > 0"
            >
                <template #title>{{ item.name }}</template>
                <nav-list :list="item.children"></nav-list>
            </el-sub-menu>
            <!-- 情况二：没子集的情况： -->
            <template v-else>
                <el-menu-item :index="item.path">{{ item.name }}</el-menu-item>
            </template>
        </template>
    </div>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue'
import { RouteRecordRaw } from 'vue-router'

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<RouteRecordRaw[]>,
      required: true
    }
  }
})
</script>
<style lang='scss' scoped>
.nav-list {
  display: flex;
  .el-menu-item.is-active {
    color: #ffc400 !important;
  }
  .el-menu-item {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

@media screen and (max-width: 480px) {
  .nav-list {
    flex-direction: column-reverse;
  }
}
</style>
