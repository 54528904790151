<template>
    <xy-header
        fixed
        class="header"
        :style="[background ? `background-color: ${background}` : '#272727']"
    >
        <div class="container">
            <a href="#" class="logo">
                <span>动漫素材分享</span>
            </a>
            <div class="container__menu">
                <el-menu
                    mode="horizontal"
                    :default-active="activeIndex"
                    router
                    @select="handleMenuSelect"
                    v-bind="$attrs"
                >
                    <nav-list :list="routes"></nav-list>
                </el-menu>
                <slot></slot>
            </div>
        </div>
    </xy-header>
</template>

<script lang='ts'>
import { computed, PropType } from 'vue'
import NavList from './nav-list.vue'
import { RouteRecordRaw, useRoute } from 'vue-router'

export default {
  props: {
    background: {
      type: String as PropType<string>
    },
    routes: {
      type: Array as PropType<RouteRecordRaw[]>,
      required: true
    }
  },
  components: {
    NavList
  },
  setup(props: Readonly<{}>) {
    const route = useRoute()

    const activeIndex = computed(() => {
      return route.path
    })
    const handleMenuSelect = (key: string, keyPath: string[]) => {}
    return {
      activeIndex,
      handleMenuSelect
    }
  }
}
</script>
<style lang='scss' scoped>
@include b(container) {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;

  @include e(menu) {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .el-menu {
      border: none;
      height: 100%;
      width: 100% !important;
      justify-content: flex-end;
    }
  }
}
@include b(logo) {
  display: inline-block;
  text-align: center;
  height: 56px;
  width: 16%;
  line-height: 56px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  min-width: 130px;
  padding: 0 5px;
}

@media screen and (max-width: 495px) {
  .container__menu {
    width: 45%;
    margin-left: 20%;
  }
}
</style>
