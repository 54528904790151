export interface MaterialState {
  page: IMaterialPageType
}

export interface IMaterialPageType {
  current: number
  pages: number
  size: number
  total: number

}

export const state: MaterialState = {
  page: {
    current: 1,
    pages: 6,
    size: 30,
    total: 153
  }
}
