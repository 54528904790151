<template>
  <y-footer>
    <div class="content">
      <div class="m_link">
        <div class="item">
          <span>不良内容举报邮箱</span>
          <span>user_service@newlang.cn</span>
        </div>
        <div class="item">
          <span>商务合作邮箱</span>
          <span>bd@newlang.cn</span>
        </div>
      </div>
      <div class="record">
        <a href="http://ccm.mct.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/50c4f62ed8ae4d1b88d178518ec7030c"
          target="_blank">
          <p>
            <span class="record_wenwangwen">
              <img src="@/assets/img/wenwangwen.png" /> &nbsp;粤网文〔2022〕0678-051号
            </span>
          </p>
        </a>
        <a href="http://beian.miit.gov.cn/" target="_blank">
          <span class="beian">粤ICP备2021062879号-1 &nbsp;&nbsp;</span>
          <span>电话：020-85624842</span>
        </a>
        <p class="company">
          <img src="@/assets/img/footer.png" />
        </p>
      </div>
    </div>
  </y-footer>
</template>

<script>
export default {
  name: 'XyFooter'
}
</script>

<style lang="scss" scoped>
footer {
  background-color: #272727;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.m_link {
  width: 50%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 6px;
}

.item {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.item .link {
  display: block;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  margin: 5px 0;
  text-decoration: none;
  font-size: 15px;
}

.item span {
  margin: 5px 3px;
}

.record {
  margin: 4px 0;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999ff;
}

.record p {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999ff;
}

.record a {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999ff;
}

.beian {
  color: #fff !important;

  &:hover {
    text-decoration: underline;
  }
}

.company {
  margin-top: 5px;

  img {
    width: auto;
    height: 20px;
  }
}

.record_wenwangwen {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 5px;

  img {
    width: 30px;
    height: 30px;
  }

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 975px) {
  .m_link {
    width: 80%;
  }
}

@media screen and (max-width: 825px) {
  .m_link {
    width: 80%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .item_links {
    display: flex;
    flex-direction: row;
  }

  .item_links .link {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    line-height: 11px;
  }

  .item_links .link_first::after {
    display: block;
    content: '';
    background-color: #cccccc;
    height: 11px;
    width: 1px;
    margin-left: 17px;
    margin-right: 17px;
  }

  .item_links .link_second::after {
    display: block;
    content: '';
    background-color: #cccccc;
    height: 11px;
    width: 1px;
    margin-left: 17px;
    margin-right: 17px;
  }

  .item span {
    font-size: 12px;
    font-weight: 200;
    margin-right: 3px;
  }

  .item:nth-child(2) {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .record_hint {
    margin: 0 auto;
  }

  .record_hint span:nth-child(2) {
    display: block;
  }
}
</style>
